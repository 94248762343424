import { useEffect, useState } from "react";
import { BrixDevtools } from "@squadx-developer/brix-library";

import { getPlayerParams } from "./utils/player";
import { PlayerParams, Video } from "./types/player";
import { getVod } from "./apis/player";
import VideoPlayer from "./components/VideoPlayer";
import packageInfo from "./../package.json";
import "./video.css";

export default function Player({ props }: Props) {
  const [videoData, setVideoData] = useState<Video>();
  const {
    playerParams: params,
    debug: { syDebug },
  } = getPlayerParams(props);
  const env = process.env.REACT_APP_ENV as "prod" | "stage" | "dev";

  const videoProps = {
    env,
    params,
    videoData,
  };

  useEffect(() => {
    (!props?.url || !props.filePath) &&
      params.id &&
      getVod(params.id).then(setVideoData);
  }, []);

  if ((!props?.url || !props?.filePath) && !videoData) return null;
  return (
    <>
      <VideoPlayer {...videoProps} />
      <BrixDevtools
        packageVersion={packageInfo.version}
        debug={syDebug}
        onMount={() => {
          window?.__brix_console?.("version", packageInfo.version);
          window?.__brix_console?.("entryTime", new Date().toLocaleString());
        }}
      />
    </>
  );
}

interface Props {
  props?: Partial<PlayerParams>;
}
